<template>
  <b-modal
    id="code-details-modal"
    ref="code-details-modal"
    size="lg"
    :ok-title="$t('global.cancel')"
    ok-only
  >
    <div>
      <div v-if="code" class="code-property-container">
        <div>
          <label>ID</label>
          <p>{{ code.id }}</p>
        </div>
        <div>
          <label>{{ $t("global.nameGeo") }}</label>
          <p>{{ code.campaign.nameGeo || "" }}</p>
        </div>
        <div>
          <label>{{ $t("global.nameEng") }}</label>
          <p>{{ code.campaign.nameEng || "" }}</p>
        </div>
        <div>
          <label>{{ $t("codes.type") }}</label>
          <p>{{ code.type ? typeList[code.type] : "" }}</p>
        </div>
        <div>
          <label>{{ $t("codes.code") }}</label>
          <p>{{ code.code || "" }}</p>
        </div>
        <div v-if="code.discountAmount">
          <label>{{ $t("codes.discountAmount") }}</label>
          <p>{{ code.discountAmount || "" }}</p>
        </div>
        <div v-if="code.discountPercent">
          <label>{{ $t("codes.discountPercent") }}</label>
          <p>{{ code.discountPercent || "" }}%</p>
        </div>
      </div>
      <!-- editable fields below -->
      <hr />
      <div v-if="code" class="code-property-container">
        <div>
          <b-form-group
            :label="$t('codes.activationEndsAt')"
            label-for="activationEndsAt"
          >
            <flat-pickr
              v-model="code.activationEndsAt"
              class="date-control"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i:ss',
              }"
            />
          </b-form-group>
        </div>
        <div>
          <b-form-group
            :label="$t('codes.usageEndsAt')"
            label-for="usageEndsAt"
          >
            <flat-pickr
              v-model="code.usageEndsAt"
              label="Usage End Date"
              class="date-control"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i:ss',
              }"
            />
          </b-form-group>
        </div>
        <div v-if="code.type === 2">
          <b-form-group :label="$t('codes.count')" label-for="count">
            <b-form-input
              v-model="code.count"
              name="count"
              placeholder="count"
              type="number"
            />
          </b-form-group>
        </div>
      </div>
      <div>
        <b-button
          v-if="isEditAllowed"
          variant="success"
          type="submit"
          :disabled="busy"
          @click="updateCodeHandler"
        >
          {{ $t("global.update") }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BFormGroup, BFormInput, BButton } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "CodeDetailsModal",
  components: {
    BModal,
    BFormGroup,
    flatPickr,
    BFormInput,
    BButton,
  },
  props: {
    code: {
      type: Object,
      default: () => {},
    },
    busy: {
      type: Boolean,
      defualt: false,
    },
    update: {
      type: Function,
      default: () => {},
    },
    toast: {
      type: Function,
      default: () => {},
    },
    isEditAllowed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      t: null,
      typeList: {
        1: "One Time",
        2: "Sale",
      },
    };
  },
  created() {
    this.t = this.$i18n.t.bind(this.$i18n);
  },
  methods: {
    async updateCodeHandler() {
      const payload = {
        id: this.code.id,
        count: parseFloat(this.code.count),
        activationEndsAt: this.code.activationEndsAt,
        usageEndsAt: this.code.usageEndsAt,
      };
      try {
        await this.update(payload);
        this.toast("Code updated successfully");
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.code-property-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}
label {
  font-weight: bold !important;
}

.date-control {
  width: 100%;
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  display: block;
}
</style>
