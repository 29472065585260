<template>
  <div class="d-flex align-items-center flex-wrap">
    <template v-if="filtersVisible">
      <b-form-group label="From" label-for="from" class="mr-1">
        <b-form-input v-model="from" placeholder="ID" name="from" />
      </b-form-group>
      <b-form-group label="To" label-for="to" class="mr-1">
        <b-form-input v-model="to" placeholder="ID" name="to" />
      </b-form-group>
    </template>
    <b-button
      :disabled="filtersVisible && (!from || !to)"
      variant="primary"
      :class="iconOnly ? 'p-0-8' : 'p-1'"
      @click="exportHandler(from, to)"
    >
      <span v-if="!iconOnly">
          {{ $t("global.export") }}
      </span>
      <feather-icon v-if="iconOnly" icon="DownloadIcon"></feather-icon>
    </b-button>
  </div>
</template>

<script>
import { BFormInput, BFormGroup, BButton } from "bootstrap-vue";

export default {
  name: "ExportFromTo",
  components: {
    BFormInput,
    BFormGroup,
    BButton,
  },
  props: {
    exportHandler: {
      type: Function,
      required: true,
    },
    filtersVisible: {
      type: Boolean,
      default: true
    },
    iconOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      from: null,
      to: null,
    };
  },
};
</script>

<style></style>
