<template>
  <div>
    <CodeDetailsModal
      :code="modalCode"
      :busy="isTableBusy"
      :update="updateCode"
      :toast="toast"
      :isEditAllowed="isEditAllowed"
    />
    <hr />
    <!-- filters jere -->
    <div class="d-flex flex-wrap justify-content-between">
      <FiltersAndSearch
        :id.sync="id"
        :searchKey.sync="searchKey"
        :sortOrder.sync="sortOrder"
        disableActiveOnly
      />
      <!-- end of filters -->
      <!-- export -->
      <ExportFromTo :exportHandler="exportCodesHandler" class="mb-2" />
      <!-- end export -->
    </div>
    <b-table
      sticky-header
      :items="codesList.codes"
      bordered
      responsive
      :fields="computedFields"
      :busy="isTableBusy"
      hover
      class="products-table shadow-sm rounded"
      show-empty
      empty-text="No matching records found"
      small
      @row-clicked="handleRowClick"
    >
      <template #cell(action)="data">
        <!-- <b-button variant="flat-danger" class="btn-icon mr-1" @click="deleteCodeHandler(data.item)">
          <feather-icon size="16" icon="TrashIcon" />
        </b-button> -->
        <b-button
          variant="flat-success"
          class="btn-icon mr-1"
          @click="handleRowClick(data.item)"
        >
          <feather-icon v-if="isEditAllowed" size="16" icon="EditIcon" />
          <feather-icon v-else size="16" icon="EyeIcon" />
        </b-button>
      </template>
    </b-table>
    <Pagination
      :fetch-data="getAndUpdateCodes"
      :total-rows="totalRows"
      :take.sync="take"
      :skip.sync="skip"
    />
  </div>
</template>

<script>
import { BTable, BButton } from "bootstrap-vue";
import { mapState, mapActions } from "vuex";
import Pagination from "@/views/components/Pagination/Pagination.vue";
import ExportFromTo from "@/views/components/ExportFromTo/ExportFromTo.vue";
import isAllowed from "@core/utils/isAllowed";
import FiltersAndSearch from "@/views/components/FiltersAndSearch/FiltersAndSearch.vue";
import CodeDetailsModal from "./CodeDetailsModal.vue";

const FileSaver = require("file-saver");

export default {
  name: "CodesTable",
  components: {
    BTable,
    Pagination,
    BButton,
    CodeDetailsModal,
    FiltersAndSearch,
    ExportFromTo,
  },
  props: {
    toast: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalCode: null,
      totalRows: 0,
      take: 20,
      skip: 0,
      id: "",
      searchKey: "",
      sortOrder: 2,
    };
  },
  computed: {
    ...mapState("promotionModule", ["codesList", "isTableBusy", "singleCode"]),
    isEditAllowed() {
      // import isAllowed from "@core/utils/isAllowed";
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      return isAllowed(["Admin", "Editor"], roles);
    },
    computedFields() {
      return [
        {
          key: "id",
        },
        {
          key: "action",
          label: this.$i18n.t("global.action"),
          class: "actions-column",
        },
        {
          key: "code",
          label: this.$i18n.t("codes.code"),
        },
        {
          key: "nameGeo",
          label: this.$i18n.t("codes.name"),
          formatter: (value, key, item) =>
            `${item.campaign.nameGeo} / ${item.campaign.nameEng}`,
        },
      ];
    },
  },
  watch: {
    searchKey() {
      this.getAndUpdateCodes();
    },
    take() {
      this.getAndUpdateCodes();
    },
    id() {
      this.getAndUpdateCodes();
    },
  },
  created() {
    this.getAndUpdateCodes();
  },
  methods: {
    ...mapActions("promotionModule", [
      "getCodes",
      "deleteCodeById",
      "updateCode",
      "getCodeById",
      "exportCodes",
    ]),
    async exportCodesHandler(from, to) {
      const payload = { from, to };

      try {
        const response = await this.exportCodes(payload);
        const date = new Date().toLocaleDateString();
        FileSaver.saveAs(response.data, `Promo_codes-${date}.xlsx`);
        this.toast("Exported successfully", "success");
      } catch (error) {
        const responsObj = await error.response.data.text();
        this.toast(JSON.parse(responsObj).error.message, "danger");
      }
    },
    async handleRowClick(item) {
      console.log(item);
      const modalId = "code-details-modal";
      try {
        await this.getCodeById(item.id);
        this.modalCode = this.singleCode.code;
        this.$bvModal.show(modalId);
      } catch (e) {
        console.log(e);
      }
    },
    async getAndUpdateCodes() {
      try {
        await this.getCodes({
          take: this.take,
          skip: this.skip,
          id: this.id,
          sortOrder: this.sortOrder,
          searchKey: this.searchKey,
        });
        this.totalRows = this.codesList.count;
      } catch (e) {
        console.log(e);
      }
    },
    async deleteCodeHandler(item) {
      const response = await this.$bvModal.msgBoxConfirm(
        "Are you sure you want to delete this promotion ?",
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (response) {
        try {
          await this.deleteCodeById(item.id);
          this.getAndUpdateCodes();
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
};
</script>

<style>
.actions-column {
  width: 40px;
}
</style>
