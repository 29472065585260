<template>
  <div class="mb-2">
    <validation-observer ref="codeValidation">
      <div class="code-add-container">
        <div class="grid-3 mb-1" style="min-height: 90px">
          <b-form-group
            :label="`${t.t('codes.referralType')} *`"
            label-for="Referral type"
            class="mb-0 w-100 md-w-50 mr-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Referral Type"
              rules="required"
            >
              <b-form-select
                v-model="referralType"
                :options="referralTypes"
                name="Referral Type"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="`${t.t('codes.chooseCampaign')} *`"
            label-for="cmapaignId"
            class="mb-0 w-100 md-w-50 mr-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Campaign"
              rules="required"
            >
              <b-form-select v-model="campaignId" name="Referral Type">
                <b-form-select-option
                  v-for="camp in promotionsList.campaigns"
                  :key="camp.nameGeo"
                  :value="camp.id"
                  >{{
                    `${camp.nameGeo} / ${camp.nameEng}`
                  }}</b-form-select-option>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div>
            <b-form-group
              v-if="referralType === 2"
              :label="t.t('codes.code')"
              label-for="code"
              class="mb-0 w-100 md-w-50 mr-2"
            >
              <b-form-input
                v-model="code"
                :placeholder="t.t('codes.code')"
                name="code"
              />
              <div v-if="false" class="d-flex">
                <b-form-checkbox
                  v-model="isMultiplyUsable"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  switch
                  inline
                />
                <span class="mt-1" style="font-size: 12px">{{
                  t.t("codes.multiply")
                }}</span>
              </div>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="mb-1 grid-4">
        <b-form-group
          :label="`${t.t('codes.count')} *`"
          label-for="count"
          class="mb-0 w-100 md-w-50 mr-2"
        >
          <validation-provider
            #default="{ errors }"
            name="count"
            rules="required"
          >
            <b-form-input
              v-model="count"
              :placeholder="t.t('codes.count')"
              type="number"
              name="count"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-if="referralType === 1"
          :label="t.t('codes.codeLength')"
          label-for="codeLength"
          class="mb-0 w-100 md-w-50 mr-2"
        >
          <b-form-input
            v-model="codeLength"
            :placeholder="t.t('codes.codeLength')"
            type="number"
            name="codeLength"
          />
        </b-form-group>
        <b-form-group
          v-if="referralType === 1"
          :label="`${t.t('codes.discountAmount')} *`"
          label-for="discountAmount"
          class="mb-0 w-100 md-w-50 mr-2"
        >
          <validation-provider
            #default="{ errors }"
            name="discountAmount"
            rules="required"
          >
            <b-form-input
              v-model="discountAmount"
              :placeholder="`${t.t('codes.discountAmount')} *`"
              type="number"
              name="discountAmount"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-if="referralType === 2"
          :label="`${t.t('codes.discountPercent')} *`"
          label-for="discountPercent"
          class="mb-0 w-100 md-w-50 mr-2"
        >
          <validation-provider
            #default="{ errors }"
            name="discountPercent"
            rules="required"
          >
            <b-form-input
              v-model="discountPercent"
              placeholder="discountPercent"
              type="number"
              name="discountPercent"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="grid-4">
        <!-- <b-form-group :label="t.t('codes.activationEndsAt')" label-for="activationEndsAt">
          <flat-pickr
            v-model="activationEndsAt"
            :label="t.t('codes.activationEndsAt')"
            class="date-control"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i:ss',
            }"
          />
        </b-form-group> -->
        <b-form-group :label="t.t('codes.usageEndsAt')" label-for="usageEndsAt">
          <flat-pickr
            v-model="usageEndsAt"
            :label="t.t('codes.usageEndsAt')"
            class="date-control"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i:ss',
            }"
          />
        </b-form-group>
      </div>
      <div v-if="referralType === 1" class="d-flex mb-1">
        <b-form-group
          :label="t.t('global.descriptionGeo')"
          label-for="descriptionGeo"
          class="mb-0 w-100 md-w-50 mr-2"
        >
          <b-form-input
            v-model="descriptionGeo"
            :placeholder="t.t('global.descriptionGeo')"
            name="descriptionGeo"
          />
        </b-form-group>
        <b-form-group
          :label="t.t('global.descriptionEng')"
          label-for="descriptionEng"
          class="mb-0 w-100 md-w-50"
        >
          <b-form-input
            v-model="descriptionEng"
            :placeholder="t.t('global.descriptionEng')"
            name="descriptionEng"
          />
        </b-form-group>
      </div>
    </validation-observer>
    <b-button variant="success" @click.prevent="validateAndSubmitCode">
      {{ t.t("global.submit") }}
    </b-button>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BFormGroup,
  BButton,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "flatpickr/dist/flatpickr.css";
import { mapState, mapActions } from "vuex";

const FileSaver = require("file-saver");

export default {
  name: "CreatesCodesForm",
  components: {
    BFormGroup,
    BButton,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BFormSelectOption,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      required,
      referralType: null, // required
      campaignId: null, // required
      count: null, // required
      codeLength: null,
      activationEndsAt: "",
      usageEndsAt: "", // required
      discountAmount: 0, // required
      descriptionGeo: "",
      descriptionEng: "",
      code: "",
      isMultiplyUsable: true,
      discountPercent: 0, // required
      t: null,
    };
  },
  props: {
    toast: {
      type: Function,
      default: () => {},
    },
  },
  created() {
    this.getPromotions({ skip: 0, take: 100 });
    this.t = this.$i18n;
    // this.$validator.localize("ar");
  },
  computed: {
    ...mapState("promotionModule", ["promotionsList"]),
    referralTypes() {
      return [
        { text: this.$i18n.t("codes.oneTime"), value: 1 },
        { text: this.$i18n.t("codes.sale"), value: 2 },
      ];
    },
  },
  methods: {
    ...mapActions("promotionModule", [
      "getPromotions",
      "createOneTimeCode",
      "getCodes",
    ]),
    createCustomPayload() {
      if (this.referralType === 1) {
        const payload = {
          referralType: this.referralType,
          campaignId: this.campaignId,
          count: this.count,
          codeLength: this.codeLength,
          // activationEndsAt: this.activationEndsAt,
          usageEndsAt: this.usageEndsAt,
          discountAmount: this.discountAmount,
          descriptionGeo: this.descriptionGeo,
          descriptionEng: this.descriptionEng,
        };
        return payload;
      }
      const payload = {
        referralType: this.referralType,
        code: this.code,
        campaignId: this.campaignId,
        // activationEndsAt: this.activationEndsAt,
        usageEndsAt: this.usageEndsAt,
        isMultiplyUsable: this.isMultiplyUsable,
        discountPercent: this.discountPercent,
        count: this.count,
      };
      return payload;
    },
    resetFormFields() {
      this.referralType = null;
      this.campaignId = null;
      this.count = null;
      this.codeLength = null;
      this.activationEndsAt = "";
      this.usageEndsAt = "";
      this.discountAmount = 0;
      this.descriptionGeo = "";
      this.descriptionEng = "";
      this.code = "";
      this.isMultiplyUsable = true;
      this.discountPercent = 0;
    },
    async validateAndSubmitCode() {
      console.log(this.$validator);
      const result = await this.$refs.codeValidation.validate();
      if (result) {
        const payload = this.createCustomPayload();
        try {
          const response = await this.createOneTimeCode(payload);
          const date = new Date().toLocaleDateString();
          FileSaver.saveAs(response.data, `PromoCodes-${date}.xlsx`);
          this.resetFormFields();
          this.toast("Promo Code Created");
          requestAnimationFrame(() => {
            this.$refs.codeValidation.reset();
          });
        } catch (error) {
          console.log(error);
        } finally {
          this.getCodes({ skip: 0, take: 20 });
        }
      }
    },
  },
};
</script>

<style scoped>
.grid-3 {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}
.grid-4 {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.date-control {
  width: 100%;
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  display: block;
}
</style>
