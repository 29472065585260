<template>
  <div class="d-flex align-items-center flex-wrap">
    <b-form-group
      v-if="idValue !== null"
      :label="$i18n.t('search.id')"
      label-for="idValue"
      class="mr-1"
    >
      <b-form-input
        v-model="idValue"
        placeholder="ID"
        name="idValue"
        style="max-width: 70px"
      />
    </b-form-group>
    <b-form-group
      v-if="searchValue !== null"
      :label="$i18n.t('global.search')"
      label-for="searchKey"
      class="mr-1"
    >
      <b-form-input
        v-model="searchValue"
        placeholder="Search"
        name="searchKey"
      />
    </b-form-group>
    <b-form-group
      v-if="primaryStatusValue !== null"
      :label="primaryStatusLabel"
      label-for="i-name-eng"
      class="mr-1"
    >
      <b-form-select
        v-model="primaryStatusValue"
        :options="primaryStatusFields"
      >
        <template #first>
          <b-form-select-option :value="''">
            {{ $i18n.t("global.all") }}
          </b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>
    <b-form-group
      v-if="paymentStatusValue !== null"
      :label="$i18n.t('search.paymentStatus')"
      label-for="i-name-eng"
      class="mr-1"
    >
      <b-form-select
        v-model="paymentStatusValue"
        :options="paymentStatusFields"
        clearable
      >
        <template #first>
          <b-form-select-option :value="''">
            {{ $i18n.t("global.all") }}
          </b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>
    <b-form-group
      v-if="startDateValue !== null"
      :label="$i18n.t('orders.startDate')"
      label-for="startDate"
      class="mr-1"
      style="max-width: 18%"
    >
      <b-form-datepicker v-model="startDateValue" name="startdate" :date-format-options="dateFormatOptions"/>
    </b-form-group>
    <b-form-group
      v-if="endDateValue !== null"
      :label="$i18n.t('orders.endDate')"
      label-for="endDate"
      class="mr-1"
      style="max-width: 18%"
    >
      <b-form-datepicker v-model="endDateValue" name="endDate" :date-format-options="dateFormatOptions"/>
    </b-form-group>
    <b-form-group
      v-if="!disableActiveOnly"
      :label="$i18n.t('search.activeOnly')"
      label-for="activeOnly"
      class="mr-1"
    >
      <b-form-checkbox v-model="activeOnlyValue" name="activeOnly" switch />
    </b-form-group>
    <b-form-group
      v-if="priceSortValue !== null"
      :label="$i18n.t('search.priceSort')"
      label-for="priceSort"
      class="mr-1"
    >
      <b-form-checkbox v-model="priceSortValue" name="priceSort" switch />
    </b-form-group>
    <b-form-group
      v-if="sortValue !== null"
      :label="$i18n.t('search.sort')"
      label-for="i-name-eng"
      class="mr-1"
    >
      <b-form-select v-model="sortValue" :options="sortFields" />
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormCheckbox,
  BFormSelectOption,
  BFormDatepicker,
} from "bootstrap-vue";

export default {
  name: "FiltersAndSearch",
  components: {
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BFormSelectOption,
    BFormDatepicker,
  },
  props: {
    searchKey: {
      type: String || null,
      default: null,
    },
    activeOnly: {
      type: Boolean,
      default: true,
    },
    primaryStatus: {
      type: String || null,
      default: null,
    },
    primaryStatusLabel: {
      type: String || null,
      default: null,
    },
    paymentStatus: {
      type: String || null,
      default: null,
    },
    endDate: {
      type: String || null,
      default: null,
    },
    startDate: {
      type: String || null,
      default: null,
    },
    primaryStatusFields: {
      type: Object,
      default: () => {},
    },
    paymentStatusFields: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String || null,
      default: null,
    },
    dateFormatOptions: {
      type: Object,
      default: () => {
        return { year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }
      },
    },
    disableActiveOnly: {
      type: Boolean,
      default: false,
    },
    priceSort: {
      type: [Boolean, null],
      default: null,
    },
    sortOrder: {
      type: [Number, String, null],
      default: null,
    },
  },
  computed: {
    searchValue: {
      get() {
        return this.searchKey;
      },
      set(val) {
        this.$emit("update:searchKey", val);
      },
    },
    activeOnlyValue: {
      get() {
        return this.activeOnly;
      },
      set(val) {
        this.$emit("update:activeOnly", val);
      },
    },
    primaryStatusValue: {
      get() {
        return this.primaryStatus;
      },
      set(val) {
        this.$emit("update:primaryStatus", val);
      },
    },
    paymentStatusValue: {
      get() {
        return this.paymentStatus;
      },
      set(val) {
        this.$emit("update:paymentStatus", val);
      },
    },
    idValue: {
      get() {
        return this.id;
      },
      set(val) {
        this.$emit("update:id", val);
      },
    },
    startDateValue: {
      get() {
        return this.startDate;
      },
      set(val) {
        this.$emit("update:startDate", val);
      },
    },
    endDateValue: {
      get() {
        return this.endDate;
      },
      set(val) {
        this.$emit("update:endDate", val);
      },
    },
    priceSortValue: {
      get() {
        return this.priceSort;
      },
      set(val) {
        this.$emit("update:priceSort", val);
      },
    },
    sortValue: {
      get() {
        return this.sortOrder;
      },
      set(val) {
        this.$emit("update:sortOrder", val);
      },
    },
    sortFields() {
      return {
        1: this.$i18n.t("search.asc"),
        2: this.$i18n.t("search.desc"),
      };
    },
  },
  mounted() {
    this.primaryStatusLabel = this.primaryStatusLabel ?? this.$i18n.t('search.status')
  }
};
</script>
