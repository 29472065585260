<template>
  <b-card
    header="Promotions"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <!-- create promo code -->
    <CreateCodesForm v-if="isCreateAllowed" :toast="showToast" />
    <CodesTable :toast="showToast" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import isAllowed from "@core/utils/isAllowed";

import CodesTable from "./components/CodesTable.vue";
import CreateCodesForm from "./components/CreateCodesForm.vue";

export default {
  name: "Promotions",
  components: {
    BCard,
    CodesTable,
    CreateCodesForm,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  computed: {
    isCreateAllowed() {
      // import isAllowed from "@core/utils/isAllowed";
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      return isAllowed(["Admin", "Editor"], roles);
    },
  },
  methods: {
    showToast(message, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant,
        },
      });
    },
  },
};
</script>

<style></style>
